<template>
    <div>
        <h1>All Trips</h1>

        <div v-if="isTableLoading" class="text-center pt-3">
            <b-spinner label="Loading..." />
        </div>

        <template v-else>

            <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <b-button variant="primary" class="float-right" v-b-modal.add-modal @click="openAddModal">
                        <span> Add trip </span>
                    </b-button>
                </b-form-group>
            </div>

            <vue-good-table
                    :columns="columns"
                    :rows="trips"
                    :search-options="{
                      enabled: true
                    }"
                    :pagination-options="{
                      enabled: true,
                      perPage: pageLength,
                    }"
                >

                <template slot="table-column" slot-scope="props">
                    <span
                      v-if="props.column.label === 'Customer'"
                      class="d-flex align-items-center"
                    >
                        <span class="mr-25">
                          {{props.column.label}}
                        </span>
                        <feather-icon
                                icon="InfoIcon"
                                size="16"
                                v-b-tooltip.hover
                                class="text-primary"
                                title="Click on a row in this column to see customer details"
                        />
                    </span>
                    <span v-else>
                        {{props.column.label}}
                    </span>
                </template>

                <template slot="table-row" slot-scope="props">
                    <!-- Column: Action -->
                    <span v-if="props.column.field === 'action'">
                        <span>
                            <b-dropdown
                              variant="link"
                              toggle-class="text-decoration-none"
                              no-caret
                              right
                            >
                                <template v-slot:button-content>
                                  <feather-icon
                                          icon="MoreVerticalIcon"
                                          size="16"
                                          class="text-body align-middle mr-25"
                                  />
                                </template>
                                <b-dropdown-item
                                        v-if="props.row.status !== 6"
                                        v-b-modal.edit-modal
                                        @click="openEditModal(props.row)"
                                    >
                                    <feather-icon icon="Edit2Icon" class="mr-50" />
                                    <span>Edit</span>
                                </b-dropdown-item>

                                <b-dropdown-item @click="deleteItem(props.row.id)">
                                    <feather-icon icon="TrashIcon" class="mr-50" />
                                    <span>Delete</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </span>
                    </span>
                    <span v-else-if="props.column.field === 'inactive'">
                        {{props.row.inactive == 0 ? 'Active' : 'Inactive'}}
                    </span>
                    <!-- Column: Common -->
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-end flex-wrap">
                        <b-pagination
                                :value="1"
                                :total-rows="props.total"
                                :per-page="pageLength"
                                first-number
                                last-number
                                align="right"
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mt-1 mb-0"
                                @input="(value) => props.pageChanged({ currentPage: value })"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </template>
            </vue-good-table>
        </template>

        <b-modal id="edit-modal" title="Edit trip details">

            <b-form>
                <b-form-group label="Name:" label-for="input-name">
                    <validation-provider #default="{ errors }" name="name" rules="required">
                        <b-form-input id="name" v-model="edited.name" placeholder="Trip Name" required />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group label="Price:" label-for="input-name">
                    <validation-provider #default="{ errors }" name="price" rules="required">
                        <b-form-input id="price" type="number" v-model="edited.price" placeholder="Trip Price" required />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group label="Additional Price:" label-for="input-name">
                    <validation-provider #default="{ errors }" name="additional_price"  >
                        <b-form-input id="additional_price" type="number" v-model="edited.additional_price" placeholder="Additional Price"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group label="Deposit (%):" label-for="input-name">
                    <validation-provider #default="{ errors }" name="deposit"  >
                        <b-form-input id="deposit" type="number" v-model="edited.deposit" placeholder="Deposit"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group label="Active from:" label-for="input-name">
                    <validation-provider #default="{ errors }" name="active_from" rules="required">
                        <b-form-datepicker
                            id="active_from"
                            :min="minDate"
                            v-model="edited.active_from"
                            locale="en"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group label="Active to:" label-for="input-name">
                    <validation-provider #default="{ errors }" name="active_to" rules="required">
                        <b-form-datepicker
                            id="active_to"
                            :min="minDate"
                            v-model="edited.active_to"
                            locale="en"
                            @context="onContext"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-checkbox v-model="edited.inactive" switch class="mb-1">
                    Inactive Trip
                </b-form-checkbox>
            </b-form>

            <template #modal-footer>
                <b-button variant="outline-secondary" class="float-right" @click="$bvModal.hide('edit-modal')">
                    Close
                </b-button>
                <b-button variant="primary" class="float-right" @click="editSubmit" :disabled="isEditLoading">
                    <b-spinner v-if="isEditLoading" label="Spinning" small />
                    <span v-else> Save </span>
                </b-button>
            </template>

        </b-modal>

        <b-modal id="add-modal" title="Add new trip">

            <b-form>

                <b-form-group label="Boat:" label-for="input-name">
                    <validation-provider #default="{ errors }" name="boat_id" rules="required">
                        <!--    <b-form-input v-model="edited.boat_id" placeholder="Boat Name" required />-->
                        <v-select required
                          id="boat_id"
                          v-model="edited.boat_id"
                          item-value="id"
                          label="name"
                          item-text="name"
                          :options="boats"
                          :clearable="false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group label="Additional Price:" label-for="input-name">
                    <validation-provider #default="{ errors }" name="additional_price"  >
                        <b-form-input type="number" v-model="edited.additional_price" placeholder="Additional Price"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group label="Deposit (%):" label-for="input-name">
                    <validation-provider #default="{ errors }" name="deposit"  >
                        <b-form-input type="number" v-model="edited.deposit" placeholder="Deposit"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group label="Trip name:" label-for="input-name">
                    <validation-provider #default="{ errors }" name="name" rules="required">
                        <b-form-input v-model="edited.name" placeholder="Trip Name" required />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group label="Price:" label-for="input-name">
                    <validation-provider #default="{ errors }" name="price" rules="required">
                        <b-form-input type="number" v-model="edited.price" placeholder="Trip Price" required />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group label="Active from:" label-for="input-name">
                    <validation-provider #default="{ errors }" name="active_from" rules="required">
                        <b-form-datepicker
                                :min="minDate"
                                v-model="edited.active_from"
                                locale="en"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group label="Active to:" label-for="input-name">
                    <validation-provider #default="{ errors }" name="active_to" rules="required">
                        <b-form-datepicker
                                :min="minDate"
                                v-model="edited.active_to"
                                locale="en"
                                @context="onContext"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

            </b-form>

            <template #modal-footer>
                <b-button variant="outline-secondary" class="float-right" @click="$bvModal.hide('add-modal')">
                    Close
                </b-button>
                <b-button variant="primary" class="float-right" @click="addSubmit" :disabled="isAddingLoading">
                    <b-spinner v-if="isAddingLoading" label="Spinning" small />
                    <span v-else> Save </span>
                </b-button>
            </template>

        </b-modal>
    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { required } from '@validations'
    import {
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BDropdown,
        BDropdownItem,
        VBModal,
        BForm,
        BFormDatepicker,
        BSpinner,
        BButton,
        BFormCheckbox,
        BTable,
        VBTooltip,
    } from "bootstrap-vue";
    import vSelect from "vue-select";
    import { VueGoodTable } from "vue-good-table";
    import { mapActions, mapGetters } from "vuex";
    import axios from "@/libs/axios";
    import { toastSuccess, toastFailure } from "@/libs/toastification";
    import {sweetWarning, sweetError} from "@/libs/sweet-alerts";

    export default {
        components: {
            VueGoodTable,
            BBadge,
            BPagination,
            BFormGroup,
            BFormInput,
            BDropdown,
            BDropdownItem,
            BForm,
            vSelect,
            ValidationProvider,
            ValidationObserver,
            BFormDatepicker,
            BSpinner,
            BButton,
            BFormCheckbox,
            BTable,
        },
        directives: {
            "b-modal": VBModal,
            "b-tooltip": VBTooltip,
        },
        data() {
            return {
                minDate: new Date(),
                isTableLoading: true,
                isEditLoading: false,
                isAddingLoading: false,
                columns: [

                    {
                        label: "Name",
                        field: "name",
                        type: "string",
                        filterOptions: {
                            enabled: true,
                            placeholder: "All",
                            trigger: "enter",
                        },
                    },
                    {
                        label: "Boat",
                        field: "boat_name",
                        type: "string",
                        filterOptions: {
                            enabled: true,
                            placeholder: "All",
                            trigger: "enter",
                        },
                    },
                    {
                        label: "Price",
                        field: "price",
                        width: '140px',
                        type: 'float',
                        filterOptions: {
                            enabled: true,
                            placeholder: "All",
                            trigger: "enter",
                        },
                    },
                    {
                        label: "Additional Price",
                        field: "additional_price",
                        width: '200px',
                        type: 'float',
                        filterOptions: {
                            enabled: true,
                            placeholder: "All",
                            trigger: "enter",
                        },
                    },
                    {
                        label: "Deposit (%)",
                        field: "deposit",
                        width: '140px',
                        type: 'float',
                        filterOptions: {
                            enabled: true,
                            placeholder: "All",
                            trigger: "enter",
                        },
                    },
                    {
                        label: "Status",
                        field: "inactive",
                        sortable: true,
                    },
                    {
                        label: "Action",
                        field: "action",
                        thClass: "text-right pr-1",
                        tdClass: "text-right",
                        sortable: false,
                    }
                ],
                pageLength: 10,
                edited: {
                    id: null,
                    boat_id: null,
                    name: null,
                    price: null,
                    deposit: null,
                    additional_price: null,
                    active_from: null,
                    active_to: null,
                    inactive: null
                }
            };
        },
        computed: {
            ...mapGetters({
                trips: "trips/trips",
                boats: 'boats/boats',
            })
        },
        watch: {
            ////
        },
        async mounted() {

            await this.loadTrips()
                .then(()=>{
                    this.loadBoats()
                        .catch(() => { toastFailure('Something went wrong.') });
                })
                .catch(() => {
                    toastFailure('Something went wrong.')
                });
            this.isTableLoading = false;
        },
        methods: {
            ...mapActions({
                loadTrips: "trips/loadTrips",
                updateTrip: "trips/updateTrip",
                deleteTrip: "trips/deleteTrip",
                addTrip: "trips/addTrip",
                loadBoats: 'boats/loadBoats',
            }),
            onContext(ctx) {
                this.formatted = ctx.selectedFormatted;
                this.selected = ctx.selectedYMD;
            },
            showError(responseData=null, warning=false) {

                if( responseData != null && responseData.message != undefined && responseData.message != null ){

                    let errorMSG = responseData.message + '</br>';
                    if(responseData.errors){

                        Object.keys(responseData.errors).map(function(errorKey){
                            responseData.errors[errorKey].map(function(value, key){
                                errorMSG += value + '</br>';
                            });
                        });
                    }

                    if( warning ){
                        sweetWarning('Warning', errorMSG);
                    }
                    else{
                        sweetError('Error', errorMSG);
                    }
                }
                else{
                    toastFailure("Something went wrong.");
                }
            },
            openEditModal(row) {
                this.edited.id = row.id;
                this.edited.boat_id = row.boat_id;
                this.edited.boat_name = row.boat_name;
                this.edited.name = row.name;
                this.edited.price = row.price;
                this.edited.deposit = row.deposit;
                this.edited.additional_price = row.additional_price;
                this.edited.active_from = row.active_from;
                this.edited.active_to = row.active_to;
                this.edited.inactive = row.inactive == 1 ? true : false;
            },
            openAddModal() {
                this.edited.id = 0;
                this.edited.boat_id = 0;
                this.edited.boat_name = '';
                this.edited.name = '';
                this.edited.price = '';
                this.edited.deposit = '';
                this.edited.additional_price = '';
                this.edited.active_from = '';
                this.edited.active_to = '';
            },

            async deleteItem(id) {
                if (await this.confirmationMessage('Are you sure you want to delete this trip?')) {
                    this.deleteTrip(id)
                        .then(() => {
                            toastSuccess("Trip has been deleted.");
                        })
                        .catch(() => {
                            toastFailure("Something went wrong while deleting the trip");
                        });
                }
            },
            async addSubmit() {

                this.isAddingLoading = true;
                this.addTrip({
                    name: this.edited.name,
                    price: this.edited.price,
                    deposit: this.edited.deposit,
                    additional_price: this.edited.additional_price,
                    boat_id: this.edited.boat_id.id,
                    active_from: this.edited.active_from,
                    active_to: this.edited.active_to
                })
                    .then(() => {
                        toastSuccess("New trip added.");
                    })
                    .catch((error) => {
                        toastFailure("Something went wrong while adding a new trip.");
                    })
                    .finally(() => {
                        this.isAddingLoading = false;
                        this.$bvModal.hide('add-modal');
                    });
            },
            async editSubmit() {

                this.isEditLoading = true;
                this.updateTrip({
                    id: this.edited.id,
                    name: this.edited.name,
                    price: this.edited.price,
                    deposit: this.edited.deposit,
                    additional_price: this.edited.additional_price,
                    boat_id: this.edited.boat_id,
                    boat_name: this.edited.boat_name,
                    active_from: this.edited.active_from,
                    active_to: this.edited.active_to,
                    inactive: this.edited.inactive,
                })
                    .then(() => {
                        toastSuccess("Your changes have been updated.");
                    })
                    .catch((error) => {
                        toastFailure("Something went wrong while updating the trip.");
                    })
                    .finally(() => {
                        this.isEditLoading = false;
                        this.$bvModal.hide('edit-modal');
                    });
            },

            onColumnFilter(params) {
                this.changeUrl(params.columnFilters);
            },
            changeUrl(values) {
                const searchParams = new URLSearchParams(window.location.search);

                /* eslint-disable-next-line */
                for (const [key, value] of Object.entries(values)) {
                    searchParams.set(key, value);
                }

                window.history.pushState(
                    {},
                    null,
                    `${this.$route.path}?${searchParams.toString()}`
                );
            },

            confirmationMessage(message) {
                return this.$bvModal.msgBoxConfirm(message, {
                    title: 'Please Confirm',
                    okVariant: 'primary',
                    okTitle: 'Yes',
                    cancelTitle: 'No',
                });
            }
        },
    };
</script>

<style>
</style>
